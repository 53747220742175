.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: linear-gradient(#D7D7D7,#FFFFFF);
    padding: 15px;
    margin-bottom: 30px;
    position: relative;
}

.header img{
    width: auto;
    height: /*42px*/ 47px;
}

.header-menu{
    display: flex;
    flex-direction: row;
}

.header-menu ul{
    padding: 0em 0em 0em 4em;
    list-style:none;
}

.header-menu ul li{
    position: relative;
    display: inline;
    margin: 10px;
}

.header-menu ul li a{
    width: 100px;
    height: 70px; 
    font-size: 17px;
    color: #555555;
    text-decoration: none;
    position: relative;
}

.header-menu ul li a:hover{ 
    cursor: pointer;
    color: #333333;
}

.header-user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    width: 250px;
    top: 25px;
    right: 15px;
}

.header-user h1 {
    text-align: right;
    font-size: 16px;
}

.header-user span {
    cursor: pointer;
    font-family: 'Raleway';
    font-size: 12px;
    margin-left: 5px;
    text-decoration: underline;
}

.header-user-options {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-logout-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
}

.icon-exit-header {
    display: block;
    width: 14px;
    height: 11.333px;
    background-size: 14px 11.333px; 
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../Image/icon-exit.svg');
}

