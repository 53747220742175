.sticky {
    position: fixed;
    top: 90.63px;
}

.admin-header{
    z-index: 99999;
    position: fixed;
    width: 100vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(#D7D7D7,#FFFFFF);
    padding: 20px 0px 20px 0px;
}

.admin-header-space{
    width: 100vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 20px 0px;    
}

.admin-header img{
    width: auto;
    height: 47px;
}

.admin-painel-name {
    padding: 12px 0px 12px 0px;
}

.admin-exit-button {
    color: #555555;
}

.admin-header-logo {
    position: absolute;
    left: 50px;
}

.admin-exit-button {
    position: absolute;
    top: 38px;
    right: 50px;
}

div.admin-tab-container {
    overflow: scroll;
    height: 80vh;
}

.admin-tab {
    position: relative;
    border-spacing: 8px;
    padding-left: 50px;
}

thead th.admin-tab-head-item {
    position: sticky;
    top: 0px;
    z-index: 10000;
}

thead th.admin-tab-head-item:first-child {
    left: 0;
    z-index: 20000;
}

tbody th#admin-tab-data-item-name {
    position: sticky;
    left: 0;
}

.admin-tab-head-item {
    padding: 30px 20px 30px 20px;
    color: #4CC1FF;
    background-color: #FFFFFF;
    text-align: center;
}

.admin-tab-head-item-module-name {
    color: #333333;
}


.admin-tab-data-item {
    font-family: 'Raleway';
}

.admin-tab td {
    font-family: 'Raleway';
    white-space: nowrap;
}

.admin-tab th {
    white-space: nowrap;
}

.admin-tab-data-item {
    text-align: center;
    padding: 30px 20px 30px 20px;
    background-color: #F2F2F2;
}

#admin-tab-head-item-user-name {
    color: #FF054F;
}

#admin-tab-head-item-total {
    color: #FF054F;
}

#admin-tab-data-item-name {
    padding: 0px;
    background-color: #D7D7D7;
    position: sticky;
}

#admin-tab-data-name {
    display: inline-block;
    font-family: 'Raleway-Bold';
    padding: 30px 40px 30px 40px;
    margin-left: 50px;
    height: 100%;
    text-align: center;
}

#admin-tab-data-number {
    position: absolute;
    font-family: 'Raleway-Bold';
    padding: 40px 10px 40px 10px; 
    width: 50px;
    top: 0;
    height: 100%;
    background-color: #F2F2F2;
}

.admin-module-progress-wrapper {
    position: relative;
    text-align: center;
    font-size: 15px;
    margin-bottom: 10px;
}

.admin-module-progress-icon {
    display: inline-block;
    top: 7px;
    width: 14px;
    height: 15px;
    margin-top: 6px;
    margin-right: 6px;
    background-size: 14px 15px; 
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../Image/icon-progress-numbers.svg');
}

.admin-module-progress-icon-done {
    display: inline-block;
    top: 7px;
    width: 18px;
    height: 14px;
    margin-top: 6px;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../Image/icon-progress-done.svg');
}

.admin-module-progress-bar {
    border-radius: 5px;
}

.admin-module-progress-bar-progress {
    border-radius: 5px;
    height: 7px;
}

