#section-content{
    padding: 0px 30px 30px 30px;
}

.section-content-not-collapsed {
    width: 54vw;
}

.section-content-collapsed {
    width: 70vw;
}

.section-text{
    font-family: 'Raleway';
    text-align: justify;
}

.section-centralized-subtitle {
    margin-top: 20px;
    font-family: 'Raleway';
    text-align: center;
}

.subsection-wrapper {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
}

.subsection-content {
    font-family: 'Raleway';
    text-align: justify;
}

.subsection-content p {
    margin-bottom: 10px;
}

.subsection-name-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.subsection-name {
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
}

.subsection-wrapper-horizontal {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px 0px 0px 10px;
}

.subsection-name-wrapper-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.subsection-name-wrapper-horizontal span{
    width: 110px;
    margin: 0px 10px 10px 0px;
}

.subsection-content-horizontal {
    font-family: 'Raleway';
    text-align: justify;
    margin: 0px 10px 10px 0px;
}

.section-imagem-zoom-wrapper {
    position: relative;
}

.section-image-overlay {
    background: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.section-imagem-zoom-click {
    font-family: 'Raleway';
    color: #FFFFFF;
    position: absolute;
    text-align: center;
    width: 100%;
    
}

.section-imagem-zoom-wrapper:hover {
    cursor: pointer;
}

.section-imagem-zoom-wrapper:hover .section-image-overlay {
    opacity: 1;
}

.section-imagem-zoom {
    width: 500px;
}

.section-imagem-in-zoom {
    width: 800px;
}

.section-imagem-in-zoom-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    cursor: default;
}

#lb-back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    text-align: center;
}

#lb-back.show {
    visibility: visible;
    opacity: 1;
}

