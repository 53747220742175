/* ErrorPage.css */

.error-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .error-heading {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 42px; 
    color: #FF054F;
  }
  
  .error-message {
    font-size: 24px; /* Tamanho de fonte maior para a mensagem de erro */
    color: #333333; /* Cor mais escura para a mensagem de erro */
    margin-bottom: 30px; /* Espaço extra abaixo da mensagem */
  }
  
  .error-code {
    font-size: 18px; /* Tamanho de fonte para o código de erro */
    color: #AAAAAA; /* Cor mais suave para o código de erro */
    margin-bottom: 20px; /* Espaço abaixo do código de erro */
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .button {
    padding: 12px 20px;
    margin: 0 10px; /* Espaço entre os botões */
    font-size: 18px; /* Tamanho de fonte para os botões */
    background-color: #FF054F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Efeito de transição suave */
  }
  
  .button:hover {
    background-color: #D4004F; /* Cor de destaque quando passar o mouse */
  }
  