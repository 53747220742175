.panel-login {
    width: 480px;
    height: 90vh;
    margin: 30px 40px 30px 0px;
    padding: 50px 70px;
    border-radius: 20px;
    background-color: white;
}

.panel-login-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel-login-header img {                        
    width: auto;
    height: 70px;
    object-fit: contain;
}

.panel-login-header h1 {
    margin: 0.3em 0em 2em 0em;
    font-size: 26px;
    color: #4CC1FF;
}

.panel-login-header h4 {
    margin: 1em 0em 0em 0em;
    font-family: "Raleway-SemiBold";
    font-size: 14px;
    color: #4CC1FF;
}

.panel-login-button-changePassword{
    display: flex;
    justify-content: flex-end;
}

.panel-login-button-changePassword h1{
    margin: 0em 1.5em 0em 0em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
}

.panel-login-button-changePassword a{
    text-decoration: underline;
    color: #4CC1FF;
}

.panel-login-button{
    display: flex;
    justify-content: center;
}

.buttonLogin{
    padding: 10px 30px;
    font-size: 12px;
    background-color: #FF054F;
    color: white;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
    box-shadow: 2px 2px 4px #888888;
}

.button-1{
    width: 120px;
    height: 35px;
    margin-top: 3.5em;
    border: 0px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background-color: #FF054F;
}

.icon-login {
    width: 14px;
    height: 14px;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-login-email {
    background-image: url('../../../Image/icon-signup-email.svg');
}

.icon-login-pass {
    background-image: url('../../../Image/icon-signup-pass.svg');
}

.panel-footer{
    text-align: center;
}

.panel-footer h1{
    margin-top: 3em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
}

.panel-footer a{
    text-decoration: underline;
    color: #4CC1FF;
}

