.section-title-wrapper {
    margin-bottom: 1em;
}

.section-title-wrapper a {
    text-decoration: none;
}

.section-icon {
    width: 15px;
    height: 15px;
    float: left;
    margin-right: 1em;
    background-repeat: no-repeat;
    background-position: center;
}

.section-icon-status-default {
    background-image: url('../../../Image/icon-section-default.svg');
}

.section-icon-status-selected {
    background-image: url('../../../Image/icon-section-selected.svg');
}

.section-icon-status-done {
    background-image: url('../../../Image/icon-section-done.svg');
}

.section-title-menu {
    cursor: pointer;
}