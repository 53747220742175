#certificate-not {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    text-align: center;
    cursor: pointer;
}

#certificate-not.show {
    visibility: visible;
    opacity: 1;
}

.certificate-not-alert-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
}

.certificate-not-alert {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40vw;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certificate-not-alert p {
    color: #FFFFFF;
}

.icon-certificate-atention {
    display: block;
    width: 80px;
    height: 73px;
    margin-bottom: 35px;
    background-size: 80px 73px; 
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../Image/icon-atencao.png');
}