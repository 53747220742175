.module-container{
    margin: 0px 20px 20px 20px;
}

.module-header{
    text-align: center;
}

.module-header h4{
    font-size: 14px;
    margin: 0em;
}

.module-details {
    position: relative;
    margin-bottom: 20px;
}

.module-header h1{
    display: inline-block;
    font-size: 19px;
    color: #02A7F0;
}

.module-info-icon {
    display: inline-block;
    position: absolute;
    top: 7px;
    width: 12px;
    height: 12px;
    margin-left: 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../Image/icon-module-info.svg');
}

.module-image img{
    width: 170px;
    border-radius: 25px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .5);
}

.module-progress-details {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.module-progress-wrapper {
    position: relative;
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.module-progress-icon {
    display: inline-block;
    top: 7px;
    width: 14px;
    height: 15px;
    margin-top: 6px;
    margin-right: 6px;
    background-size: 14px 15px; 
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../Image/icon-progress-numbers.svg');
}

.module-progress-icon-done {
    display: inline-block;
    top: 7px;
    width: 18px;
    height: 14px;
    margin-top: 6px;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../Image/icon-progress-done.svg');
}

.module-progress-bar {
    width: 90%;
    border-radius: 5px;
}

.module-progress-bar-progress {
    border-radius: 5px;
    height: 7px;
}