@font-face{
  font-family: 'Raleway-Bold';
  src: url('./Fonts/raleway/Raleway-Bold.ttf');
}

@font-face{
  font-family: 'Raleway-SemiBold';
  src: url('./Fonts/raleway/Raleway-SemiBold.ttf');
}

@font-face{
  font-family: 'Raleway';
  src: url('./Fonts/raleway/Raleway-Regular.ttf');
}

* {
  box-sizing: border-box;
}

@font-face{
  font-family: 'Raleway-Medium';
  src: url('./Fonts/raleway/Raleway-Medium.ttf');
}

body, html{
  background-color: #FFFFFF;
  font-family: 'Raleway-Bold';
}
