.faq-wrapper {
    margin: 0 100px 0 100px;
}

.faq-header h1{
    font-size: 20px;
    color: #FF054F;
}

.faq-content-wrapper {
    display: flex;
    flex-direction: row;
}

.faq-questions-wrapper {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 8px 0 8px 0;
}

.faq-question {
    cursor: pointer;
    font-family: 'Raleway-Semibold';
    color: #4CC1FF;
    padding: 8px;
}

.faq-question-selected {
    font-family: 'Raleway-Bold';
    color: #FF054F;
    background-color: #F2F2F2;
    border-radius: 10px 0px 0px 10px;
}

.faq-answers-wrapper {
    width: 30%;
}

.faq-answer {
    font-family: 'Raleway';
    font-size: 13px;
    border-radius: 10px;
    background-color: #F2F2F2;
    text-align: justify;
    padding: 12px;
    height: 100%;
}