.question-button {
    text-align: center;
    margin-bottom: 20px;
}

.question-description {
    text-align: justify;
    margin-top: 1em;
    margin-bottom: 1em;
}

.question-select label {
    text-align: justify;
    display: block;
    margin-left: 2em;
    margin-bottom: 1em;
}

.question-cols-wrapper {
    display: flex;
    flex-direction: row;
}

.question-col-left  {
    width: 20%;
    text-align: justify
}

.question-col-right {
    width: 80%;
    padding: 10px 10px 0px 10px;
    border: 1px solid black;
}

.question-col-right p {
    margin-bottom: 10px;
    text-align: justify;
}