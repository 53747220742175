.modules-course-header h1{
    font-size: 20px;
    color: #FF054F;
    text-align: center;
}

.modules-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
}