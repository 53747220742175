/*
    ->Margin
        prefixo "m" + "letra refente a onde será aplicada a margin" + "valor da margin em 'em'"
        ex. mt1, referente a margin-top: 0.5em
        ex. mt2, referente a margin-top: 1em
    
    ->Fontes
        prefixo "f" + sufixo "refente a alteração"
        ex. fsizem, referente a fontes de tamanho médio
        ex. fsizep, refenente a fontes de tamanho pequeno

    ->Background Color

    ->Fonte Color
*/

/*
https://code.visualstudio.com/docs/editor/codebasics
Ctrl+Shift+L seleciona todas as ocorrencias
*/

h1, h2, h3, h4, p{ margin: 0px; padding: 0px;}

.mt1{margin-top: 0.5em;} .mt2{margin-top: 1em;} .mt3{margin-top: 1.5em;} .mt4{margin-top: 2em;} .mt5{margin-top: 2.5em;} .mt6{margin-top: 3em;}
.mb1{margin-bottom: 0.5em;} .mb2{margin-bottom: 1em;} .mb3{margin-bottom: 1.5em;} .mb4{margin-bottom: 2em;} .mb5{margin-bottom: 2.5em;} .mb6{margin-bottom: 3em;}
.ml1{margin-left: 0.5em;} .ml2{margin-left: 1em;} .ml3{margin-left: 1.5em;} .ml4{margin-left: 2em;} .ml5{margin-left: 2.5em;} .ml6{margin-left: 3em;}

.directionRow{display: flex; flex-direction: row;}
.directionColumn{display: flex; flex-direction: column;}

.bcPrimary{background-color: #FF054F;}
.bcSecundary{background-color: #4CC1FF;}
.bcFourth{background-color: #CECCCC;}
.bcFiveth{background-color:#F2F2F2;}
.bcSixth{background-color:#86FF00;}

.fcPrimary{color: #FF054F;}
.fcSecundary{color: #4CC1FF;}
.fcThird{color: white;}
.fcFourth{color:#333333;}
.fcFiveth{color:#AAAAAA;}
.fcSixth{color:#86FF00;}

.fsizeSmall{font-size: 12px;}
.fsizeXSmall{font-size: 14px;}
.fsizeMedium{font-size: 16px;}
.fsizeXMedium{font-size: 18px;}
.fsizeLarge{font-size: 22px;}
.fsizeXLarge{font-size: 26px;}

.logo-top-medium {
    display: block;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    height: 65px;
}

.buttonPrimary{
    padding: 12px 20px;
    font-size: 12px;
    background-color: #FF054F;
    color: white;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
}

.buttonPrimary:disabled{
    background-color: #CECCCC;
}
.link-button-1 {
    width: 120px;
    height: 37.61px;
    margin-top: 5em;
    border: 0px;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    background-color: #FF054F;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    padding: 12px;
    text-align: center;
}

.button-radio-Primary{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button-radio-Primary-text{
    color: #AAAAAA;
}

.button-radio-Primary input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: #eee;
    border: 2.5px solid #AAAAAA;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.button-radio-Primary:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.button-radio-Primary input:checked ~ .checkmark {
    border: 2.5px solid #FF054F;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.button-radio-Primary input:checked ~ .checkmark:after {
    display: block;
}

  /* Style the indicator (dot/circle) */
.button-radio-Primary .checkmark:after {
    top: 2.5px;
    left: 2.5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #FF054F;
}

/*Título 1*/
.t1{
    margin: 0em 0em 0em 0em;
    font-size: 22px;
    color: #4CC1FF;
}

::-webkit-scrollbar { 
    width: 4px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #333333; 
    border-radius: 2px;    
}     

.error-warn {
    color: #FF054F;
    font-size: 12px;
    text-align: center;
    position: relative;
}

.error-warn p {
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.success-warn {
    color: #4CC1FF;
    font-size: 12px;
    text-align: center;
    position: relative;    
}

.success-warn p {
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}