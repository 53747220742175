.img-extra-content {
    width: 260px;
    border-radius: 10px;
    box-shadow: 1.5px 1.5px 2px 1px rgba(202, 202, 202, 0.95);
}

.video-extra-content {
    position: relative;
    width: 260px;
    height: 182.64px;
    border-radius: 10px;
    padding: 15px;
}

.video-extra-content iframe{
    width: 230px;
    height: 120px;
    border: none;
}

.video-extra-content-paragraph {
    font-family: 'Raleway-Medium';
    font-size: 10px;
    text-align: center;
}

.paragrafo-extra-content {
    width: 260px;
    border-radius: 10px;
    padding: 15px;
}

.paragrafo-extra-content-paragraph {
    font-family: 'Raleway-Medium';
    font-size: 10px;
    text-align: justify;
}

.paragrafo-at-extra-content {
    width: 260px;
    height: 200px;
    border-radius: 10px;
    padding: 15px;
}

.paragrafo-at-extra-content-title {
    font-family: 'Raleway-Bold';
    text-align: center;
}

.paragrafo-at-extra-content-paragraph {
    margin-top: 10px;
    padding-right: 5px;
    height: 140px;
    overflow-y: scroll;
}

.paragrafo-at-extra-content-paragraph p {
    font-family: 'Raleway-Medium';
    font-size: 10px;
    text-align: justify;
    margin-bottom: 5px;
}

.imagem-links-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 260px;
    height: 200px;
    border-radius: 10px;
    padding: 15px;
}

.imagem-links-img-wrapper {
    display: flex;
    flex-direction: row;
}

.imagem-links-descricao {
    font-family: 'Raleway-Medium';
    font-size: 10px;
    text-align: center;
}

.imagem-links-img {
    width: 90px;
    margin: 0px 5px 15px 5px;
}

.imagem-links-img-horizontal {
    width: 200px;
    margin: 0px 5px 15px 5px;
}

.section-references {
    width: 260px;
    height: 200px;
    margin-top: 10px;
    border-radius: 10px;
    padding: 15px;
}

.section-references h4 {
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
}

.section-reference {
    font-size: 12px;
    font-family: 'Raleway-Medium';
    padding-bottom: 15px;
}

.section-references-wrapper {
    height: 135px;
    overflow-y: scroll;
}

.sgi-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 260px;
    height: 200px;
    border-radius: 10px;
    padding: 15px;
}

.sgi-title {
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
}

.sgi-arrow {
    font-family: 'Raleway-Medium';
    position: absolute;
    top: 12px;
    cursor: pointer;
}

.sgi-arrow-right {
    right: 15px;
}

.sgi-arrow-left {
    left: 15px;
}

.sgi-content {
    height: 160px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sgi-content p {
    font-family: 'Raleway-Medium';
    font-size: 10px;
    text-align: justify;
    margin-bottom: 10px;
    margin-right: 5px;
}

.sgi-content img {
    width: 150px;
}

.vg-arrow {
    font-family: 'Raleway-Medium';
    position: absolute;
    top: 80px;
    cursor: pointer;
}

.vg-arrow-right {
    right: 4px;
}

.vg-arrow-left {
    left: 4px;
}

.ig-extra-content {
    position: relative;
    width: 260px;
    height: 200px;
    margin-top: 10px;
    border-radius: 10px;
    padding: 15px;    
}

.ig-extra-content-content {
    height: 160px;
    overflow-y: scroll;
    font-family: 'Raleway-Medium';
    font-size: 12px;
}

.ig-extra-content-content li ul p {
    margin-bottom: 10px;
}

.ig-extra-content-content p {
    margin-top: 10px;
}

.ig-extra-content-content ul {
    margin-left: -20px;
}