.about-course-header h1{
    font-size: 20px;
    color: #FF054F;
}

.about-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about-paragraphs-wrapper {
    margin-right: 30px;
}

.about-paragraphs-wrapper p {
    font-family: 'Raleway-Medium';
    font-size: 15px;
    margin-bottom: 10px;
    text-indent: 25px;
    text-align: justify;
}

.about-logo-slogan {
    width: 400px;
}