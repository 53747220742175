.test-container-wrapper {
    font-family: 'Raleway';
    font-weight: normal;
    overflow: hidden;
}

.test-container-wrapper h1 {
    font-family: 'Raleway-Bold';
    margin-top: 60px;
    text-align: center;
}

.test-question-wrapper {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.abs-left-bottom {
    position: fixed;
    width: 280px;
    left: -135px;
    bottom: 10px;
}

.abs-right-bottom {
    position: fixed;
    width: 280px;
    right: -135px;
    bottom: 10px;
}

.test-progress-bar {
    position: fixed;
    border-radius: 5px;
    bottom: 0px;
    width: 100%;
}

.test-progress-bar-progress {
    border-radius: 5px;
    width: 10%;
    height: 10px;
}