.menu-module-progress-bar {
    margin-top: 5px;
    border-radius: 5px;
    width: 234.38px;
}

.menu-module-progress-bar-progress {
    border-radius: 5px;
    width: 10%;
    height: 7px;
}