.panel-singup{
    width: 480px;
    height: 90vh;
    margin: 30px 40px 30px 0px;
    padding: 50px 60px;
    border-radius: 20px;
    background-color: white;
}

.panel-singup-fields {
    overflow-y: scroll;
    height: 70%; 
}

.panel-singup-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel-singup-header img{                        
    width: auto;
    height: 70px;
    object-fit: contain;
}

.panel-singup-header h1{
    margin: 0em 0em 0em 0em;
    font-size: 26px;
    color: #4CC1FF;
}

.panel-singup-header h4{
    margin: 1em 0em 3em 0em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
}

.panel-singup-header h4 a{
    text-decoration: none;
    color: #4CC1FF;
}

.input-2{
    display: flex;
    flex-direction: row;
    margin: 0em 0em 0.5em 0em;
    padding-right: 10px;
    border: 1px solid #797979;
    border-radius: 5px;
    /* overflow: hidden; */
}

.input-2-error{
    border-color: #FF054F;
}

.input-2-icon{
    white-space: nowrap;
    margin: auto;
    padding: 5px 10px;
}

.input-2-icon h1{
    margin: 0 0 0 22px;
    padding: 0em;
    font-size: 12px;
}

.icon-signup {
    width: 14px;
    height: 14px;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-signup-name {
    background-image: url('../../../Image/icon-signup-name.svg');
}

.icon-signup-dob {
    background-image: url('../../../Image/icon-signup-dob.svg');
}

.icon-signup-email {
    background-image: url('../../../Image/icon-signup-email.svg');
}

.icon-signup-inst {
    background-image: url('../../../Image/icon-signup-inst.svg');
}

.icon-signup-pass {
    background-image: url('../../../Image/icon-signup-pass.svg');
}

.icon-signup-semester {
    background-size: 18px;
    background-image: url('../../../Image/icon-signup-semester.svg');
}

.icon-signup-sex {
    background-image: url('../../../Image/icon-signup-sex.svg');
}

.icon-signup-civil {
    background-image: url('../../../Image/icon-signup-civil.svg');
}

.input-2-field{
    width: 90%;
}

.input-2-field input{
    width: 100%;
    height: 27px;
    border: 0px;
    outline: none;
    font-size: 12px;
}

.input-2-field select {
    height: 27px;
    float: right;
    font-family: 'Raleway';
    font-size: 12px;
    outline: none;
    border-color: transparent;
}

.input-2-field .select-full-width {
    width: 100%;
}

.input-2-field .select-left {
    float: left;
}

#select-inst {
    width: 170px;
    position: relative;
}

#select-inst option {
    position: absolute;
}

.panel-signup-button-changePassword{
    display: flex;
    justify-content: flex-end;
}

.panel-signup-button-changePassword h1{
    margin: 0em 1.5em 0em 0em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
}

.panel-signup-button-changePassword a{
    text-decoration: none;
    color: #4CC1FF;
}

.panel-signup-button{
    display: flex;
    justify-content: center;
}

.button-1{
    width: 120px;
    height: 35px;
    margin-top: 2em;
    border: 0px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background-color: #FF054F;
}

