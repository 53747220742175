.wrapper{
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

h1{
    margin: 0px;
    padding: 0px;
}

.main-container-div{
    padding: 0px 70px;
}

/*.main-div-content{
    
}*/