.course-finalized-wrapper {
    font-family: 'Raleway';
    font-weight: normal;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-image: url("../../../Image/bg-finalizado.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.course-finalized-inner {
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.course-finalized-inner h1 {
    text-align: left;
    font-size: 36px;
    color: white;
    font-family: 'Raleway';
    font-weight: normal;
    padding: 0px;
    margin-bottom: 10px;
}

.course-finalized-inner h2 {
    text-align: left;
    font-size: 26px;
    color: white;
    font-family: 'Raleway';
    font-weight: normal;
    padding: 0px;
}

.course-finalized-text {
    margin-left: 30px;
}

.icon-stamp {
    height: 190px;
}