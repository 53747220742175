#edit-profile-click-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    padding: 0px 10px 0px 10px;
}

#edit-profile-click-wrapper.show {
    background-color: #FFFFFF;
    z-index: 9999;
}

#edit-profile-click-wrapper span {
    margin: 0px;
}

#edit-profile-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#edit-profile-wrapper.show {
    visibility: visible;
    opacity: 1;
}

#edit-close-span {
    text-decoration: none;
    font-family: 'Raleway-Bold';
    font-size: 14px;
    position: absolute;
    top: 25px;
    right: 25px;
}

.panel-edit-profile-updated {
    margin-top: 20px;
    font-size: 14px;
    font-family: 'Raleway';
    color: #FF054F;
}

.panel-edit-profile{
    width: 480px;
    height: 82vh;
    margin: 70px 40px 30px 0px;
    padding: 25px 60px;
    border-radius: 20px;
    background-color: white;
    position: relative;
}

.panel-edit-profile-fields {
    overflow-y: scroll;
}

.panel-edit-profile-header{
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 10px;
}

.panel-edit-profile-header img{                        
    width: auto;
    height: 70px;
    object-fit: contain;
}

.panel-edit-profile-header h1{
    margin: 0em 0em 0em 0em;
    font-size: 22px;
    color: #FF054F;
    text-align: left;
}

.panel-edit-profile-header h4{
    margin: 1em 0em 3em 0em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
}

.panel-edit-profile-header h4 a{
    text-decoration: none;
    color: #4CC1FF;
}

.input-2-edit {
    display: flex;
    flex-direction: row;
    margin: 0em 0em 0.3em 0em;
    padding-right: 10px;
    border: 1px solid #797979;
    border-radius: 5px;
    /* overflow: hidden; */
}

.input-2-error{
    border-color: #FF054F;
}

.input-2-icon{
    white-space: nowrap;
    margin: auto;
    padding: 5px 10px;
}

.input-2-icon h1{
    margin: 0 0 0 22px;
    padding: 0em;
    font-size: 12px;
}

.icon-edit-profile {
    width: 14px;
    height: 14px;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-edit-profile-name {
    background-image: url('../../../Image/icon-signup-name.svg');
}

.icon-edit-profile-dob {
    background-image: url('../../../Image/icon-signup-dob.svg');
}

.icon-edit-profile-email {
    background-image: url('../../../Image/icon-signup-email.svg');
}

.icon-edit-profile-inst {
    background-image: url('../../../Image/icon-signup-inst.svg');
}

.icon-edit-profile-pass {
    background-image: url('../../../Image/icon-signup-pass.svg');
}

.icon-edit-profile-semester {
    background-size: 18px;
    background-image: url('../../../Image/icon-signup-semester.svg');
}

.icon-edit-profile-sex {
    background-image: url('../../../Image/icon-signup-sex.svg');
}

.icon-edit-profile-civil {
    background-image: url('../../../Image/icon-signup-civil.svg');
}

.input-2-field{
    width: 90%;
}

.input-2-field input{
    width: 100%;
    height: 27px;
    border: 0px;
    outline: none;
    font-size: 12px;
}

.input-2-field select {
    height: 27px;
    float: right;
    font-family: 'Raleway';
    font-size: 12px;
    outline: none;
    border-color: transparent;
}

.input-2-field .select-full-width {
    width: 100%;
}

.input-2-field .select-left {
    float: left;
}

#select-inst {
    width: 170px;
    position: relative;
}

#select-inst option {
    position: absolute;
}

.panel-edit-profile-button-changePassword{
    display: flex;
    justify-content: flex-end;
}

.panel-edit-profile-button-changePassword h1{
    margin: 0em 1.5em 0em 0em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
}

.panel-edit-profile-button-changePassword a{
    text-decoration: none;
    color: #4CC1FF;
}

.panel-edit-profile-buttons{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.button-1{
    width: 120px;
    height: 35px;
    margin-top: 2em;
    border: 0px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background-color: #FF054F;
}

#cancel-button-edit-profile {
    width: 120px;
    height: 37.61px;
    border: 0px;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    background-color: #FF054F;
    text-decoration: none;
    padding: 12px;
    text-align: center;
    cursor: pointer;
    margin-left: 20px;
}

.save-button-edit-profile {
    width: 120px;
    height: 37.61px;
    border: 0px;
    border-radius: 5px;
    font-size: 12px;
    color: #333333;
    background-color: #86FF00;
    text-decoration: none;
    padding: 12px;
    text-align: center;
    cursor: pointer;    
}

