.test-introduction-wrapper {
    font-family: 'Raleway';
    font-weight: normal;
    overflow: hidden;
}

.test-introduction-wrapper h1 {
    font-family: 'Raleway-Bold';
    margin-top: 60px;
    text-align: center;
}

.test-introduction-inner {
    width: 48%;
    margin-left: auto;
    margin-right: auto;
}


.test-introduction-inner h1 {
    margin: 0.5em;
    text-align: center;
    font-size: 16px;
    color: black;
    font-family: 'Raleway';
    font-weight: normal;
}

.abs-left-bottom {
    position: fixed;
    width: 280px;
    left: -135px;
    bottom: 0px;
}

.abs-right-bottom {
    position: fixed;
    width: 280px;
    right: -135px;
    bottom: 0px;
}