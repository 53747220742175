.not-display {
    display: none;
}

#menu-course-container{
    position: relative;
    background-color: #F2F2F2;
    /* transition: all .5s ease-in-out; */
}

.menu-course-container-not-collapsed {
    width: 290px;
}

.menu-course-container-collapsed {
    width: 62px;
}

.menu-course-header{
    position: absolute;
    width: 100%;
    height: 64px;
    border-top: 10px solid #F2F2F2;
    padding: 16px;
    background-color: #D7D7D7;
    z-index: 2;
    white-space: nowrap;
}

.menu-course-header-title{
    display: block;
    float: left;
    margin-left: 20px;
    color: #333333
}

.menu-course-modules{
    overflow-y: hidden;
    position: absolute;
    margin-top: 50px;
    right: 0px;
    width: 270px;
    height: 100%;
    padding: 0em 1em 0em 1em;
}

.menu-course-modules:hover {
    overflow-y: scroll;
}

.menu-course-modules-scroll {
    margin-top: 0;    
}

.course-title {
    margin-top: 84px;
    margin-left: 30px;
}

.section-content-wrapper {
    position: relative;
}

#section-sidebar {
    margin-top: 84px;
    max-width: 280px;
    overflow: hidden;
    padding: 2px 30px 2px 2px;
}

.icon-back {
    height: 16px;
    width: 17.853px;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../Image/icon-back.svg');
}

#icon-collapse-menu {
    display: block;
    float: right;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.icon-collapse-menu-not-collapsed {
    margin-top: 3px;
    height: 16px;
    width: 16px;
    background-image: url('../../Image/icon-collapse-menu.svg');
}

.icon-collapse-menu-collapsed {
    margin-top: -2px;
    height: 25px;
    width: 30px;
    background-image: url('../../Image/icon-sandwich-menu.svg');
}

.link-button-next-class {
    width: 120px;
    height: 37.61px;
    border: 0px;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    background-color: #FF054F;
    text-decoration: none;
    padding: 12px;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -60px;
    cursor: pointer;
}

.loading-next-class {
    font-size: 12px;
    color: #FF054F;
    padding: 12px;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -100px;
    cursor: pointer;   
}