.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #4CC1FF;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

.loading-text {
    margin-top: 20px;
    font-size: 24px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
