.tests-monitoring-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.test-monitoring-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 100px 20px 100px;
}

.test-monitoring-item h1 {
    margin-bottom: 50px;
}

.test-monitoring-item p {
   font-family: 'Raleway-Medium';
}

.test-monitoring-img {
    height: 200px;
    margin-bottom: 20px;
}

.link-button-test-monit {
    height: 37.61px;
    border: 0px;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    background-color: #FF054F;
    text-decoration: none;
    padding: 12px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}