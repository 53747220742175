.panel-reset{
    position: relative;
    width: 480px;
    height: 90vh;
    margin: 30px 40px 30px 0px;
    padding: 50px 60px;
    border-radius: 20px;
    background-color: white;
}

.panel-reset-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel-reset-header img{                        
    width: auto;
    height: 70px;
    object-fit: contain;
}

.panel-reset-header h1{
    margin: 0em 0em 0em 0em;
    font-size: 26px;
    color: #4CC1FF;
}

.panel-reset-header h4{
    margin: 1em 0em 6em 0em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
    text-align: center;
}

.panel-reset-header h4 a{
    text-decoration: none;
    color: #4CC1FF;
}

.input-2{
    display: flex;
    flex-direction: row;
    margin: 0em 0em 0.5em 0em;
    padding-right: 10px;
    border: 1px solid #797979;
    border-radius: 5px;
    /* overflow: hidden; */
}

.input-2-error{
    border-color: #FF054F;
}

.input-2-icon{
    white-space: nowrap;
    margin: auto;
    padding: 5px 10px;
}

.input-2-icon h1{
    margin: 0 0 0 22px;
    padding: 0em;
    font-size: 12px;
}

.icon-reset {
    width: 14px;
    height: 14px;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-reset-email {
    background-image: url('../../../Image/icon-signup-email.svg');
}

.icon-reset-pass {
    background-image: url('../../../Image/icon-signup-pass.svg');
}

.input-2-field{
    width: 90%;
}

.input-2-field input{
    width: 100%;
    height: 27px;
    border: 0px;
    outline: none;
    font-size: 12px;
}

.button-1{
    width: 120px;
    height: 35px;
    margin-top: 2em;
    border: 0px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background-color: #FF054F;
}

.panel-reset-button{
    display: flex;
    justify-content: center;
}

.panel-reset-footer{
    margin-top: 60px;
    text-align: center;
}

.panel-reset-footer h1{
    margin-bottom: 1em;
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 100;
}

.panel-reset-footer a{
    text-decoration: none;
    color: #4CC1FF;
}

.panel-reseted-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.panel-reseted-done h2 {
    color: #86FF00;
    text-align: center;
    margin-bottom: 40px;
}

.panel-reseted-done a {
    color: #4CC1FF;
    font-size: 14px;
}

.icon-reseted-password {
    display: block;
    width: 112px;
    height: 112px;
    background-size: 112px;
    background-image: url('../../../Image/icon-password-reseted.svg');
    background-repeat: no-repeat;
    margin-bottom: 20px;
}